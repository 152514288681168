import React from "react";

import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  AppBar: {
    color: "#333 !important",
    backgroundColor: "white !important",
    borderBottom: "2px solid #EFF3F6",
    boxShadow: "0 5px 26px 0 rgba(67,94,131,.15)",
  },
  Toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    display: "inline-block",
    fontWeight: 600,
  },
}));

export const HomeNavbar = () => {
  // states
  const [shouldNavbarShow, setShouldNavbarShow] = React.useState(false);

  // hooks
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  React.useEffect(() => {
    const pathname = location.pathname;
    setShouldNavbarShow(!pathname.startsWith("/dashboard"));
  }, [location.pathname]);

  return (
    shouldNavbarShow && (
      <AppBar elevation={0} position="sticky" className={classes.AppBar}>
        <Toolbar className={classes.Toolbar}>
          <Typography variant="h6" className={classes.title}>
            <Link to="/" component={RouterLink} style={{textDecoration:"none"}}>
          AssistXGateway API</Link>
          </Typography>

          <div className="appbar-menu">
            {!user && (
              <Box display="flex" alignItems="center" gap={1}>
                <Button
                  onClick={() => navigate("/login")}
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
                <Button onClick={() => navigate("/register")} variant="text">
                  Register
                </Button>
              </Box>
            )}

            {user && (
              <Link to="/dashboard" component={RouterLink}>
                <Button color="inherit">Dashboard</Button>
              </Link>
            )}
          </div>
        </Toolbar>
      </AppBar>
    )
  );
};
