import React, { useRef } from 'react'
import bg from '../assets/images/bg.jpg'
import {
  Button,
  Card,
  CardContent,
  Container, Grid, Paper
} from '@mui/material'


export const HomeScreen = () => {


  return (
    <>
      <Container maxWidth={"100%"} style={{backgroundColor:"#002984",height:'800px'}}>
        <Grid container spacing={2} >
          <Grid item xs={6}  >
          <div style={{margin:"5rem"}}>
            <p style={{fontSize:"50px",color:"white"}}>Integrate our powerful and flexible APIs into your existing systems 
              to streamline your workflow and boost your business efficiency. 
              Our APIs provide seamless connectivity with our ecosystem for customized solutions.</p>
          </div>
          </Grid>
          <Grid item xs={6}  >
          <div style={{margin:"20px"}}>
            <Paper style={{backgroundColor:"#002984"}} variant="outlined">
              {/* <CardContent> */}
                <img src={bg} alt="img" width={"100%"}  />
              {/* </CardContent> */}
            </Paper>
          </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
