import React from "react";

import { Route, Routes } from "react-router-dom";

import  APIKeyScreen from "../../screens/APIKeys/APIKeyScreen.js";
import { PrivateRoute } from "../middlewares/PrivateRoute";

export const KeyRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<PrivateRoute component={APIKeyScreen} />}
      />
    </Routes>
  );
};
