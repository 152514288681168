import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api", // use for production
    // baseUrl: process.env.NODE_ENV === "development"
    // ? "/api"
    // : `http://localhost:4000/api`, // use if you want to test production build in local environment
    prepareHeaders: (headers, { getState }) => {
      const user = getState().auth.user;
      if (user) {
        headers.set("authorization", [
          `Bearer ${user.token}`
        ]);
      }

      return headers;
    },
  }),
  tagTypes: [
    "User",
    "APIKeys",
    "Usages",
    "Plans"
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/user/login",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "/user/register",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = apiSlice;
