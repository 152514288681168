import React from "react";

import ReusableSidebar from "./ReusableSidebar";

const AppSidebar = () => {
  const sideMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "DashboardCustomizeOutlined",
    },
    {
      title: "API Keys",
      path: "/dashboard/api-keys",
      icon: "KeyOutlined",
    },
    {
      title: "Usage",
      path: "/dashboard/usage",
      icon: "EqualizerOutlined",
    },
    {
      title: "Reports",
      path: "/dashboard/reports",
      icon: "EqualizerOutlined",
    },
    // {
    //   title: "Account Details",
    //   path: "/dashboard/account",
    //   icon: "PeopleAltOutlined",
    // },
    {
      title: "Manage Plan",
      path: "/dashboard/my-plans",
      icon: "PaymentsOutlined",
    },
    // {
    //   title: "Billing",
    //   path: "/dashboard/billing",
    //   icon: "CreditCardOutlined",
    // },
    {
      title: "Documentation",
      icon: "ArticleOutlined",
      subMenus: [
        {
          title: "Endpoints",
          path: "/dashboard/endpoints",
          icon: "BookmarkOutlined",
        },
        {
          title: "Supports",
          path: "/dashboard/news-sources",
          icon: "HelpOutlined",
        },
      ],
    },
  ];

  return <ReusableSidebar menuList={sideMenus} />;
};

export default AppSidebar;
