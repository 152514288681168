import React from "react";

import {
  Box,
  Button,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import Search from "@mui/icons-material/Search";

import { DATE_RANGE_FILTER } from "../../constants/staticList";

const INITIAL_STATE = { fromDate: "", toDate: "" };

export const QuickDateRangeToggle = ({
  defaultFilter = DATE_RANGE_FILTER[1],
  onFilterSelect = () => {},
  onToggle = () => {},
}) => {
  const [dateRangeSelected, setDateRangeSelected] = React.useState(false);

  const [selectedFilter, setSelectedFilter] = React.useState(defaultFilter);

  const filterDateParams = React.useMemo(() => {
    switch (selectedFilter) {
      case DATE_RANGE_FILTER[0]:
        return { fromDate: moment().format("YYYY-MM-DD") };

      case DATE_RANGE_FILTER[1]:
        return {
          fromDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
          toDate: moment().format("YYYY-MM-DD"),
          // toDate: moment().format("YYYY-MM-DD  hh:mm:ss"),
        };

      case DATE_RANGE_FILTER[2]:
        return {
          fromDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
          toDate: moment().format("YYYY-MM-DD"),
          // toDate: moment().format("YYYY-MM-DD  hh:mm:ss"),
        };

      default:
        return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  // methods
  const handleSelectFilter = (filter) => {
    setSelectedFilter(filter);
  };

  const handleToggleSwitch = (e) => {
    const isDateRange = e.target.checked;
    setDateRangeSelected(isDateRange);
    reset({ ...INITIAL_STATE });
  };

  const handleToggleDateFilters = (dateRange) => {
    onFilterSelect("Custom");
    onToggle(dateRange);
  };

  // react form hook
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...INITIAL_STATE,
    },
  });

  React.useEffect(() => {
    if (defaultFilter) setSelectedFilter(defaultFilter);
  }, [defaultFilter]);

  React.useEffect(() => {
    handleToggleDateFilters(filterDateParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDateParams]);

  React.useEffect(() => {
    onFilterSelect(selectedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
        <Typography style={{ color: "gray", fontSize: 12 }}>
          Custom Date Range
        </Typography>

        <Switch
          size="small"
          checked={dateRangeSelected}
          onChange={handleToggleSwitch}
        />
      </Box>

      {!dateRangeSelected ? (
        <Paper variant="outlined">
          <Box display="flex" alignItems="center" gap={1} padding={0.5}>
            {DATE_RANGE_FILTER.map((filter) => (
              <Button
                key={filter}
                size="small"
                variant={filter === selectedFilter ? "contained" : "text"}
                onClick={() => handleSelectFilter(filter)}
              >
                {filter}
              </Button>
            ))}
          </Box>
        </Paper>
      ) : (
        <Box maxWidth={400}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box display="flex" gap={1.5}>
              <Box>
                <Controller
                  name="fromDate"
                  control={control}
                  rules={{ required: "Select From Date" }}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      inputFormat="MM/DD/YYYY"
                      size="small"
                      // disabled={isBusy}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="From Date"
                          error={!!errors.fromDate}
                        />
                      )}
                    />
                  )}
                />

                {errors.fromDate && (
                  <p className="error">{errors.fromDate.message}</p>
                )}
              </Box>

              <Box>
                <Controller
                  name="toDate"
                  control={control}
                  rules={{ required: "Select To Date" }}
                  render={({ field: { onChange, ...restFields } }) => (
                    <DesktopDatePicker
                      inputFormat="MM/DD/YYYY"
                      size="small"
                      onChange={(event) => onChange(event)}
                      // disabled={isBusy}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="To Date"
                          error={!!errors.toDate}
                          size="small"
                        />
                      )}
                      {...restFields}
                    />
                  )}
                />

                {errors.toDate && (
                  <p className="error">{errors.toDate.message}</p>
                )}
              </Box>

              <Button
                variant="contained"
                onClick={handleSubmit((data) => handleToggleDateFilters(data))}
              >
                <Search />
              </Button>
            </Box>
          </LocalizationProvider>
        </Box>
      )}
    </Box>
  );
};
