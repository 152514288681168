import React from "react";
import {
  TextField,
  FormControl,
  Typography,
  Link,
  Button,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import { LoadingButton } from "../LoadingButton";

import { useLoginMutation } from "../../state/apiSlice";
import { useRegisterUserMutation } from "../../state/userSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../state/authSlice";

export const RegisterForm = () => {
  // hooks
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // rtk queries
  // const [registerUser, { isLoading: isRegistering }] = useRegisterMutation();

  const [registerUser, { isLoading: registeringUser }] =
    useRegisterUserMutation();

  const [loginUser, { isLoading: loggingUser }] = useLoginMutation();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role:"Free"
    },
  });

  const onSubmitRegister = async (data) => {
    if (registeringUser) return;

    try {
      await registerUser(data).unwrap();
      await loginUser(data)
        .unwrap()
        .then((res) => {
          dispatch(setCredentials(res));
          navigate("/dashboard");
        })
        .catch((err) =>
          toast.error(err?.data?.message ?? "Couldn't login user")
        );
    } catch (err) {
      toast.error(err?.data?.message ?? "Couldn't register user");
    }

    // await registerUser(data)
    //   .unwrap()
    //   .then(() => {
    //     toast.success("Registered successfully. Redirecting to login page...");

    //     setTimeout(() => {
    //       navigate("/login");
    //     }, 1000);
    //   })
    //   .catch((error) => {
    //     const message =
    //       error?.data?.message || "Couldn't register. Please try again later";
    //     const errorPayload = error?.data?.payload;

    //     if (errorPayload && Array.isArray(errorPayload)) {
    //       errorPayload.forEach((err) => {
    //         setError(err.field, {
    //           type: "custom",
    //           message: err.message,
    //         });
    //       });
    //     }
    //     toast.error(message);
    //   });
  };

  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit((d) => {
            onSubmitRegister({
              ...d,
            });
          });
        }}
      >
        {/* name */}
        <Box marginBottom={4}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required",
              minLength: {
                value: 2,
                message: "Name must be minimum 2 characters long",
              },
              maxLength: {
                value: 64,
                message: "Name must not be more than 64 characters long",
              },
              pattern: {
                // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                value: /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
                message: "Name cannot contain special characters",
              },
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <Typography variant="subtitle1" sx={{ marginBottom: "8px" }}>
                  Name
                </Typography>
                <TextField
                  fullWidth
                  label=""
                  size="small"
                  {...field}
                  variant="outlined"
                  placeholder="Enter your name"
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              </FormControl>
            )}
          />
        </Box>

        {/* email */}
        <Box marginBottom={4}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email address is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email",
              },
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <Typography variant="subtitle1" sx={{ marginBottom: "8px" }}>
                  Email Address
                </Typography>
                <TextField
                  fullWidth
                  label=""
                  type="email"
                  size="small"
                  {...field}
                  variant="outlined"
                  placeholder="yourname@gmail.com"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </FormControl>
            )}
          />
        </Box>

        {/* password and confirm password */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box marginBottom={4}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be minimum 6 characters long",
                  },
                  maxLength: {
                    value: 32,
                    message:
                      "Password must not be more than 32 characters long",
                  },
                }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: "8px" }}
                    >
                      Password
                    </Typography>
                    <TextField
                      fullWidth
                      label=""
                      {...field}
                      type="password"
                      size="small"
                      variant="outlined"
                      placeholder="Enter your password"
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box marginBottom={4}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Password Confirmation is required",
                  minLength: {
                    value: 6,
                    message: "Password must be minimum 6 characters long",
                  },
                  maxLength: {
                    value: 32,
                    message:
                      "Password must not be more than 32 characters long",
                  },
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match",
                }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: "8px" }}
                    >
                      Confirm Password
                    </Typography>
                    <TextField
                      fullWidth
                      label=""
                      {...field}
                      type="password"
                      size="small"
                      variant="outlined"
                      placeholder="Enter your password again"
                      error={Boolean(errors.confirmPassword)}
                      helperText={errors.confirmPassword?.message}
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
        </Grid>

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          loading={registeringUser || loggingUser}
          loadingText="Registering..."
          sx={{ paddingBlock: "10px" }}
          onClick={handleSubmit((d) => {
            onSubmitRegister({
              ...d,
            });
          })}
        >
          Register
        </LoadingButton>

        <Box
          marginTop={1}
          marginBottom={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Typography>Already have an account?</Typography>

          <Link underline="none" component={RouterLink} to="/login">
            Login
          </Link>
        </Box>
      </form>
    </Box>
  );
};
