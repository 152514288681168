import { createSlice } from "@reduxjs/toolkit";
import { APP_CONSTANTS } from "../constants/appConstants";

const userInfoFromLocalStorage = localStorage.getItem(
  APP_CONSTANTS.LOCAL_STORAGE_KEY
)
  ? JSON.parse(localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_KEY))
  : null;

export const authSlice = createSlice({
  name: "auth",

  initialState: {
    user: userInfoFromLocalStorage,
  },
  reducers: {
    setCredentials: (state, { payload }) => {
      state.user = payload;
      localStorage.setItem(
        APP_CONSTANTS.LOCAL_STORAGE_KEY,
        JSON.stringify(payload)
      );
    },
    removeCredentials: (state) => {
      state.user = null;
      localStorage.removeItem(APP_CONSTANTS.LOCAL_STORAGE_KEY);
    },
  },
});

export const { setCredentials, removeCredentials } = authSlice.actions;

// export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectLoggedInUser = (state) => state?.auth?.user ?? null;