import React from 'react'
import { Loader } from '../../components/Loader'
import { useLazyListUsagesQuery, useListUsagesQuery } from '../../state/usageSlice'
import { LineChart } from './LineChart'
import moment from "moment";
import { Box, Skeleton } from '@mui/material';
import { QuickDateRangeToggle } from '../../components/Common/QuickDateRangeToggle';
import { DATE_RANGE_FILTER } from '../../constants/staticList';


const UsageScreen = () => {

    const [filter, setFilter] = React.useState(DATE_RANGE_FILTER[1]);

    const [dateRange, setDateRange] = React.useState({});

    const dateRangeLabels = React.useMemo(() => {
        const __getDateRangeLabels = (days, dates) =>
          new Array(days)
            .fill(null)
            .map((_) => moment(dates.add(1, "days")).format("MMM DD"));
    
        switch (filter) {
          case DATE_RANGE_FILTER[0]:
            return __getDateRangeLabels(1, moment().add(-1, "days"));
    
          case DATE_RANGE_FILTER[1]:
            return __getDateRangeLabels(7, moment().add(-7, "days"));
    
          case DATE_RANGE_FILTER[2]:
            return __getDateRangeLabels(30, moment().add(-30, "days"));
    
          case "Custom":
            const { fromDate, toDate } = dateRange;
            const startDate = moment(fromDate);
            const endDate = moment(toDate);
            const daysBetween = endDate.diff(startDate, "days") + 1;
    
            return __getDateRangeLabels(
              daysBetween,
              moment(toDate).add(-daysBetween, "days")
            );
    
          default:
            return __getDateRangeLabels(1, moment().add(-1, "days"));
        }
      }, [dateRange, filter]);

    const [getLogStats,{
        data:usageLogs=[],
        isLoading: loadingLogActivities,
        isFetching: fetchingLogActivities,
    }] = useLazyListUsagesQuery()

    const mappedChartData = React.useMemo(() => {
        const __getMappedLogDates = (logs) => {
          return logs.map((logs) => ({
            execTime: moment(logs.execTime)?.format("MMM DD"),
          }));
        };

        const __getLogDateCounts = (logs) => {
          return logs.reduce((acc, el) => {
            if (!acc[el["execTime"]]) acc[el["execTime"]] = 0;
            acc[el["execTime"]] = acc[el["execTime"]] + 1;
    
            return acc;
          }, {});
        };
    
        const __getStatsData = (logs) => {
          const logCounts = __getLogDateCounts(logs);
    
          return dateRangeLabels.map((label) =>
            Object.keys(logCounts).find((key) => key === label)
              ? logCounts[label]
              : 0
          );
        };
    
        const mappedDatesLogs = __getMappedLogDates(usageLogs);
        return [
          {
            label: "API Logs",
            data: __getStatsData(mappedDatesLogs),
            borderColor: "#1976d3",
            backgroundColor: "#1976d3",
          },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [usageLogs]);

    React.useEffect(() => {
    getLogStats({ ...dateRange });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);
  return (
    <>
    <h3>API Usage Activities</h3>
    <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        marginBottom={1}
      >
    <QuickDateRangeToggle
          onFilterSelect={(filter) => setFilter(filter)}
          onToggle={(dateRange) => setDateRange(dateRange)}
        />
    </Box>
    {!loadingLogActivities && !fetchingLogActivities ?(
        mappedChartData?.length ? (
          <LineChart
            data={{
              labels: dateRangeLabels,
              datasets: mappedChartData ?? [],
            }}
          />
        ) : (
          <div style={{ textAlign: "center", padding: "10px 0" }}>
            No Data Available.
          </div>
        )
      ) : (
        <Skeleton variant="rectangular" height={300} />
      )}
    </>
  )
}

export default UsageScreen