import { Avatar, Box, Button, Grid, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material'
import React from 'react'
import {useListPlansQuery} from '../../state/plansSlice'
import RadioButtonCheckedIcon  from '@mui/icons-material/RadioButtonChecked';

// const stripe = require('stripe')('sk_test_PGWskWTa7vvSXdSYWMjQU3wD00XSCwA23r')
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';

const PlansScreen = () => {
    const {data,isLoading} = useListPlansQuery()

    const { user } = useAuth();

  return (
    <>
    <h3>Plans</h3>
    <Grid container spacing={1}>
    {data && data.map((dt,index)=> (
      <Grid item md={4} key={index}>
      <Paper elevation={0} variant="outlined">
      <Box display="flex" paddingX={2} paddingY={1}
        style={{
            backgroundColor:user.role === dt.name ? '#7883bf': 'white'
        }}
      >
          <Box flexGrow={1} display="flex" flexDirection="column" gap={0.5}>
            <Typography 
            style={{
                fontSize: 28,
                fontWeight: 500,
                margin: "-10px 0",
                textAlign:'center'
              }}
            >{dt.name}</Typography>
  
            <Typography>
              ${dt.pricePerMonth} per Month
            </Typography>
  
            <Typography
              style={{
                fontSize: 20,
                fontWeight: 500,
                margin: "-10px 0",
              }}
            >
               {dt.requestPerMonth} requests per Month
            </Typography>
            <hr/>
            {dt.features.map((ft,index)=>(
                <ListItem index={index} key={index}>
                    <ListItemAvatar>
                    
                      <RadioButtonCheckedIcon  />
                    
                  </ListItemAvatar>
                    <ListItemText
                        primary={ft}
                    />
                </ListItem>
            ))}
            {user.role === dt.name && <p style={{textAlign:'center'}}>Current Plan</p>}
            {user.role !== dt.name && (
                    <form action="/create-checkout-session" method="POST">
                    {/* Add a hidden field with the lookup_key of your Price */}
                    <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
                    <Link to="/dashboard/my-plans/upgrade" state={{amount:dt.pricePerMonth.toFixed(2)}}>
                    {/* <a href="https://buy.stripe.com/test_14k01X2Jk1JDavScMM"> */}
                    <Button
                        variant="contained"
                        color="success"
                        >Change</Button>
                    {/* </a> */}
                    </Link>
                  </form>
                
            )}
          </Box>
              
        </Box>
        </Paper>
        </Grid>
    ))}        
    
      </Grid>
    
    </>
  )
}

export default PlansScreen