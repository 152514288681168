import React from "react";

import { Route, Routes } from "react-router-dom";

import  UsageScreen from "../../screens/Usage/UsageScreen";
import { PrivateRoute } from "../middlewares/PrivateRoute";

export const UsageRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<PrivateRoute component={UsageScreen} />}
      />
    </Routes>
  );
};
