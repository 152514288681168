import React from "react";
import { TextField, FormControl, Typography, Link, Alert } from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useLoginMutation } from "../../state/apiSlice";
import { setCredentials } from "../../state/authSlice";
import { LoadingButton } from "../LoadingButton";

export const LoginForm = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // rtk queries
  const [login, { isLoading: isLoggingIn }] = useLoginMutation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmitLogin = async (data) => {
    if (isLoggingIn) return;

    await login(data)
      .unwrap()
      .then((payload) => {
        dispatch(setCredentials(payload));

        toast.success("Successfully logged in. Redirecting...");
        setTimeout(() => {
          navigate("/dashboard");
        });
      })
      .catch((error) => {
        const message =
          error?.data?.message || "Couldn't log you in. Please try again later";
        const errorPayload = error?.data?.payload;

        if (errorPayload && Array.isArray(errorPayload)) {
          errorPayload.forEach((err) => {
            setError(err.field, {
              type: "custom",
              message: err.message,
            });
          });
        }
        toast.error(message);
      });
  };

  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit((d) => {
            onSubmitLogin({
              ...d,
            });
          });
        }}
      >
        <Box marginBottom={4}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email address is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email",
              },
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <Typography variant="subtitle1" sx={{ marginBottom: "8px" }}>
                  Email Address
                </Typography>
                <TextField
                  fullWidth
                  label=""
                  type="email"
                  size="small"
                  {...field}
                  variant="outlined"
                  placeholder="yourname@gmail.com"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </FormControl>
            )}
          />
        </Box>

        <Box marginBottom={4}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password must be minimum 6 characters long",
              },
              maxLength: {
                value: 32,
                message: "Password must not be more than 32 characters long",
              },
            }}
            render={({ field }) => (
              <FormControl fullWidth>
                <Typography variant="subtitle1" sx={{ marginBottom: "8px" }}>
                  Password
                </Typography>
                <TextField
                  fullWidth
                  label=""
                  {...field}
                  type="password"
                  size="small"
                  variant="outlined"
                  placeholder="Enter your password"
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                />
              </FormControl>
            )}
          />
        </Box>

        <Box marginBottom={4} display="flex" alignItems="center" gap={1}>
          <Typography>Forgot Password?</Typography>
          <Link underline="none">Reset your password</Link>
        </Box>

        <LoadingButton
          type="submit"
          fullWidth
          loadingText="Logging in..."
          loading={isLoggingIn}
          variant="contained"
          color="primary"
          sx={{ paddingBlock: "10px" }}
          onClick={handleSubmit((d) => {
            onSubmitLogin({
              ...d,
            });
          })}
        >
          Login
        </LoadingButton>

        <Box
          marginTop={1}
          marginBottom={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Typography>Don't have an account?</Typography>

          <Link underline="none" component={RouterLink} to="/register">
            Sign Up
          </Link>
        </Box>
      </form>
    </Box>
  );
};
