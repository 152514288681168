import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import getMuiIcon from "../../utils/getMuiIcon";

const ReusableSidebar = ({ menuList = [] }) => {
  const [open, setOpen] = React.useState({});

  const navigate = useNavigate();

  const location = useLocation();

  // methods
  const handleClick = (menu) => {
    menu?.subMenus?.length > 0
      ? setOpen({ [menu.title]: !open[menu.title] })
      : navigate(menu?.path);
  };

  // const locationSubpath = location.pathname
  //   .split("/")
  //   .filter((el) => el.length)
  //   .splice(0, 2)
  //   .join("/");

  const isActiveRoute = (path) => {
    // return path && path.includes(locationSubpath);
    return path && path === `${location.pathname}${location?.search}`;
  };

  // menu styles
  const activeMenuItemStyles = (path) => {
    return {
      backgroundColor: !!isActiveRoute(path) ? "var(--background)" : "",
      borderLeft: !!isActiveRoute(path) ? "5px solid var(--primary)" : "",
      marginLeft: !!!isActiveRoute(path) ? "5px" : "",
    };
  };

  const activeTextStyles = (path) => {
    return {
      color: !!isActiveRoute(path) ? `var(--primary)` : "",
    };
  };

  React.useEffect(() => {
    if (!menuList.length) return;

    // expand nested menu on matched path
    const activeMenu = menuList
      ?.filter((m) => m?.subMenus?.length)
      ?.find((menu) => {
        return !!menu.subMenus.find(
          (submenu) =>
            // submenu.path.includes(locationSubpath)
            submenu.path === `${location.pathname}${location?.search}`
        )
          ? menu
          : null;
      });

    activeMenu
      ? setOpen({ [activeMenu.title]: !open[activeMenu.title] })
      : setOpen({ Services: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List style={{ padding: 0 }}>
      {menuList.map((menu, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            key={`${menu.title}-${index}`}
            style={{ padding: 0 }}
            onClick={() => handleClick(menu)}
          >
            <div
              // className="flex flex-grow items-center justify-between px-4 py-2"
              style={{
                ...activeMenuItemStyles(menu.path),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                padding: "8px 16px",
              }}
            >
              <ListItemIcon>
                {getMuiIcon(menu.icon, {
                  color: !!isActiveRoute(menu.path) ? "primary" : "",
                })}
              </ListItemIcon>

              <ListItemText
                primary={menu.title}
                style={activeTextStyles(menu.path)}
              />
              {menu?.subMenus?.length
                ? open[menu.title]
                  ? getMuiIcon("KeyboardArrowUp")
                  : getMuiIcon("KeyboardArrowDown")
                : null}
            </div>
          </ListItem>

          {menu?.subMenus?.length && (
            <Collapse in={open[menu.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menu.subMenus.map((submenu, i) => (
                  <ListItem
                    key={`${submenu.title}-${index}-${i}`}
                    button
                    style={{ padding: 0 }}
                    onClick={() => navigate(submenu.path)}
                  >
                    <div
                      // className="flex flex-grow items-center justify-between py-2 pl-8"
                      style={{
                        ...activeMenuItemStyles(menu.path),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexGrow: 1,
                        // padding: "8px 16px",
                        padding: "8px 0 8px 24px",
                      }}
                    >
                      <ListItemIcon>
                        {getMuiIcon(submenu.icon, {
                          color: !!isActiveRoute(submenu.path) ? "primary" : "",
                        })}
                      </ListItemIcon>

                      <ListItemText
                        primary={submenu.title}
                        style={activeTextStyles(submenu.path)}
                      />
                    </div>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default ReusableSidebar;
