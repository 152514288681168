import { apiSlice } from "./apiSlice";

export const userSlice = apiSlice.injectEndpoints({
  tagTypes: ["User"],
  endpoints: (build) => ({
    getAllUsers: build.query({
      query: (queryParams) => {
        return {
          url: `/user`,
          params: queryParams,
        };
      },
      providesTags: (result) =>
        !result
          ? []
          : result && Array.isArray(result)
          ? [
              [
                ...result?.map((r) => ({
                  type: "User",
                  id: r._id,
                })),
              ],
              { type: "User", id: "LIST" },
            ]
          : { type: "User", id: "LIST" },
    }),
    getLoggedInUser: build.query({
      query: (params) => {
        return {
          url: `/auth/me`,
          params,
        };
      },
      providesTags: (result) => {
        return [];
      },
    }),
    getUserDetails: build.query({
      query: (params) => {
        return {
          url: `/user/details/${params.userId}`,
          params,
        };
      },
      providesTags: (result) => {
        return [
          { type: "User", id: "LIST" },
          { type: "User", id: "DETAILS" },
          { type: "User", id: result?._id },
        ];
      },
    }),
    getUserDashboardInfo: build.query({
      query: (params) => {
        return {
          url: `/user/dashboard/`,
          params,
        };
      },
      providesTags: (result) => {
        return [
          { type: "User", id: "LIST" },
          { type: "User", id: "DETAILS" },
          { type: "User", id: result?._id },
        ];
      },
    }),
    createNewUser: build.mutation({
      query: (data) => ({
        url: "/user",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    updateUser: build.mutation({
      query: (data) => ({
        url: `/user/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        { type: "User", id: "LIST" },
        { type: "Department", id: "DETAILS" },
      ],
    }),
    editUserRole: build.mutation({
      query(body) {
        return {
          url: `/user/edit-role/${body?._id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { _id }) => [
        { type: "User", _id },
        { type: "User", id: "LIST" },
        { type: "User", id: "DETAILS" },
      ],
    }),
    batchActionUsers: build.mutation({
      query: (data) => ({
        url: "/user/batch-action",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    // changeSelectedEntity: build.mutation({
    //   query(data) {
    //     const { id, ...body } = data;
    //     return {
    //       url: `/user/change-entity/${id}`,
    //       method: "PUT",
    //       body,
    //     };
    //   },
    //   invalidatesTags: (result, error, { id }) => [
    //     { type: "User", id },
    //     { type: "User", id: "LIST" },
    //   ],
    // }),
    // changeEntityDefaultRole: build.mutation({
    //   query(data) {
    //     const { id, ...body } = data;
    //     return {
    //       url: `/user/change-role/${id}`,
    //       method: "PUT",
    //       body,
    //     };
    //   },
    //   invalidatesTags: (result, error, { id }) => [
    //     { type: "User", id },
    //     { type: "User", id: "LIST" },
    //   ],
    // }),

    listUser: build.query({
      query: () => `/user/list`,
      // Provides a list of `Users` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Users` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'LIST' }` is invalidated
            [{ type: "User", id: "LIST" }],
    }),
    listUserCaseAssignment: build.query({
      query: (params) => {
        return {
          url: `/user/list/case-assignment`,
          params,
        };
      },
      // Provides a list of `Users` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Users` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'User', id: 'LIST' }` is invalidated
            [{ type: "User", id: "LIST" }],
    }),

    registerUser: build.mutation({
      query(body) {
        return {
          url: `/user/register`,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created user could show up in any lists.
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    addUser: build.mutation({
      query(body) {
        return {
          url: `/user/add`,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created user could show up in any lists.
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    detailUser: build.query({
      query: (id) => `/user/detail/${id}`,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),

    profileUser: build.query({
      query: (id) => `/user/profile/${id}`,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),

    //post request but used as query as needed
    detailUserProfile: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/profile/${id}`,
          method: "POST",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editUserProfile: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/change-profile/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editProfilePassword: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/change-profile-password/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    editDetailPassword: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/change-detail-password/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    deactivateUser: build.mutation({
      query(id) {
        return {
          url: `/user/${id}/inactive`,
          method: "PUT",
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    activateUser: build.mutation({
      query(id) {
        return {
          url: `/user/${id}/active`,
          method: "PUT",
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    addEntityToUser: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/add-entity/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this Entity `id` only.
      // In this case, `getEntity` will be re-run. `getEntities` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    changeSelectedEntity: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/change-entity/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this Entity `id` only.
      // In this case, `getEntity` will be re-run. `getEntities` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    changeEntityDefaultRole: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/change-role/${id}`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this Entity `id` only.
      // In this case, `getEntity` will be re-run. `getEntities` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    //Specific slice for Entity-Level Admins
    addEntityUser: build.mutation({
      query(body) {
        return {
          url: `/user/add/entity`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    addEntityToUserByEntity: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/add-entity/${id}/entity`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    listEntityUser: build.query({
      query: (status) => `/user/list/entity?status=${status}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),

    editEntityUser: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/edit/${id}/entity`,
          method: "PUT",
          body,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `detailUser` will be re-run. `listUser` *might*  rerun, if this id was under its results.
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    //Specific slice for Account-Level Admins
    addAccountUser: build.mutation({
      query(body) {
        return {
          url: `/user/add/account`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    addEntityToUserByAccount: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/add-entity/${id}/account`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    listAccountUser: build.query({
      query: () => `/user/list/account`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),

    listAccountEntityUser: build.query({
      query: (queryParams) => {
        return {
          url: `/user/list/account/entity`,
          params: queryParams,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),

    editAccountUser: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/edit/${id}/account`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editAccountUserRole: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/edit-role/${id}/account`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    //Specific slice for System-Level Admins
    addSystemUser: build.mutation({
      query(body) {
        return {
          url: `/user/add/system`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),

    addEntityToUserBySystem: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/add-entity/${id}/system`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    listSystemUser: build.query({
      query: (params) => {
        return {
          url: `/user/list/system`,
          params,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "User", id })),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),

    editSystemUser: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/edit/${id}/system`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),

    editSystemUserRole: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/edit-role/${id}/system`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useGetLoggedInUserQuery,
  useLazyGetLoggedInUserQuery,
  useCreateNewUserMutation,
  useUpdateUserMutation,
  useBatchActionUsersMutation,
  useEditUserRoleMutation,
  // useChangeSelectedEntityMutation,
  // useChangeEntityDefaultRoleMutation,
  useGetUserDashboardInfoQuery,
  // ******************************
  useListUserQuery,
  useListUserCaseAssignmentQuery,
  useRegisterUserMutation,
  useAddUserMutation,
  useDetailUserQuery,
  useProfileUserQuery,
  useLazyProfileUserQuery,
  useDetailUserProfileMutation, //used in setCredentials
  useEditUserProfileMutation,
  useEditProfilePasswordMutation,
  useEditDetailPasswordMutation,
  useDeactivateUserMutation,
  useActivateUserMutation,
  useAddEntityToUserMutation,
  useChangeSelectedEntityMutation,
  useChangeEntityDefaultRoleMutation,
  useAddEntityUserMutation,
  useAddEntityToUserByEntityMutation,
  useListEntityUserQuery,
  useEditEntityUserMutation,
  useAddAccountUserMutation,
  useAddEntityToUserByAccountMutation,
  useListAccountUserQuery,
  useListAccountEntityUserQuery,
  useLazyListAccountEntityUserQuery,
  useEditAccountUserMutation,
  useEditAccountUserRoleMutation,
  useAddSystemUserMutation,
  useAddEntityToUserBySystemMutation,
  useListSystemUserQuery,
  useEditSystemUserMutation,
  useEditSystemUserRoleMutation,
} = userSlice;
