import React from "react";
import { Box, Grid } from "@mui/material";

import { GetStartedHeader } from "../components/GetStarted/GetStartedHeader";
import { GetStartedOpposite } from "../components/GetStarted/GetStartedOpposite";
import { RegisterForm } from "../components/GetStarted/RegisterForm";

export const RegisterScreen = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 5rem)",
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={7} md={7}>
          <GetStartedOpposite isLoginMode={false} />
        </Grid>
        <Grid item xs={5} md={5}>
          <GetStartedHeader isLoginMode={false} />
          <Box sx={{ width: "75%", marginInline: "auto", marginTop: "2rem" }}>
            <RegisterForm />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
