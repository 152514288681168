import React from "react";
import { Box, Typography } from "@mui/material";

export const GetStartedHeader = ({ isLoginMode = true }) => {
  return (
    <Box sx={{ textAlign: "center", paddingBlock: "3rem" }}>
      <img
        style={{ width: "35px", height: "35px", objectFit: "cover" }}
        src="https://avatars.dicebear.com/api/identicon/testa.svg"
        alt="Brand Icon"
      />
      <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: "8px" }}>
        {isLoginMode ? "Login to your account" : "Register for an account"}
      </Typography>

      <Typography variant="subtitle1" className="text-secondary">
        {isLoginMode
          ? "Enter your credentials to login to your account"
          : "Signup and start integrating to your system"}
      </Typography>
    </Box>
  );
};
