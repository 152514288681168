import React from "react";

import { Navigate, Outlet } from "react-router-dom";

import { APP_CONSTANTS } from "../../constants/appConstants";

export const AuthenticatedRoute = () => {
  const rawUser = localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_KEY);

  if (!rawUser) return <Navigate to="/login" />;

  return <Outlet />;
};
