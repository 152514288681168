import React from "react";

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import Refresh from "@mui/icons-material/Refresh";
import { useAuth } from "../../hooks/useAuth";

import { useGetUserDashboardInfoQuery } from "../../state/userSlice";

const StatsCard = ({
  title = "Card Title",
  subtitle,
  count = 0,
  icon: Icon,
}) => {
  return (
    <Paper elevation={0} variant="outlined">
      <Box display="flex" paddingX={3} paddingY={2}>
        <Box flexGrow={1} display="flex" flexDirection="column" gap={0.5}>
          <Typography>{title}</Typography>

          {subtitle && (
            <Typography style={{ fontSize: 12, color: "gray" }}>
              {subtitle}
            </Typography>
          )}

          <Typography
            style={{
              fontSize: 36,
              margin: "-10px 0 -20px 0",
            }}
          >
            {count}
          </Typography>
        </Box>

        {Icon && (
          <Box flexShrink={0} display="flex" justifyContent="flex-end">
            <Icon />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

const DashboardSkeleton = () => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {new Array(2).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <Skeleton variant="text" height={60} width="30%" />

          <Grid container spacing={4}>
            {new Array(4).fill(null).map((_, index) => (
              <Grid key={index} md={3} item>
                <Skeleton variant="rect" height={120} />
              </Grid>
            ))}
          </Grid>

          <br />
        </React.Fragment>
      ))}
    </Box>
  );
};

export const DashboardHome = () => {
  const {user} = useAuth()


  // RTK Query
  const {
    data: dashboardStats,
    isLoading: loadingStats,
    isFetching: fetchingStats,
  } = useGetUserDashboardInfoQuery();


  return (
    <>
      {/* {!loadingStats && !fetchingStats ? ( */}
        <>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" marginBottom={2}>
                API Dashboard
              </Typography>

              <IconButton >
                <Refresh color="primary" />
              </IconButton>
            </Box>

            <Grid container spacing={4}>
              <Grid item md={3}>
                <StatsCard
                  title="Available"
                  subtitle="API request quota available"
                  count={dashboardStats?.stat1}
                />
              </Grid>

              <Grid item md={3}>
                <StatsCard
                  title="Today"
                  subtitle="API requests today"
                  count={dashboardStats?.stat2}
                />
              </Grid>

              <Grid item md={3}>
                <StatsCard
                  title="Monthly"
                  subtitle="API requests this month"
                  count={dashboardStats?.stat3}
                />
              </Grid>

              <Grid item md={3}>
                <StatsCard
                  title="Reset Time"
                  subtitle="API Reset Time"
                  count={new Date(dashboardStats?.stat4).toDateString()}
                />
              </Grid>
            </Grid>

          </Box>
          <br />
          <p>Account & Plan Summary</p>
          <hr />
          <em>Current Plan: {user.role}</em>
        </>
      {/* ) : (
        <DashboardSkeleton />
      )} */}
    </>
  );
};
