import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles'

import './App.css';
import { HomeNavbar } from './components/HomeNavbar';
import SidebarLayout from './components/layouts/SidebarLayouts';
import AppSidebar from './components/sidebar/AppSideBar';
import { KeyRouter } from './routers/KeyRouter/KeyRouter';
import { AuthenticatedRoute } from './routers/middlewares/AuthenticatedRoute';
import { PrivateRoute } from './routers/middlewares/PrivateRoute';
import { PlansRouter } from './routers/PlanRouter/PlansRouter';
import { UsageRouter } from './routers/Usage/UsageRouter';
import { DashboardHome } from './screens/Dashboard/DashboardHome';
import { HomeScreen } from './screens/HomeScreen';
import { LoginScreen } from './screens/LoginScreen';
import { RegisterScreen } from './screens/RegisterScreen';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ReportsRouter } from './routers/Reports/ReportsRouter';
import { EndPointsRouter } from './routers/Endpoints/EndpointRouter';

const theme = createTheme({})

function App() {
  return (
    <div className='App'>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
      />
      <ThemeProvider theme={theme}>
        <Router>
          <HomeNavbar />
          <Routes>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/register' element={<RegisterScreen />} />
            <Route path='/dashboard' element={<AuthenticatedRoute />}>
              <Route element={<SidebarLayout sidebar={AppSidebar} />}>
                <Route path='api-keys/*' element={<KeyRouter />} />
                <Route path='usage/*' element={<UsageRouter />} />
                <Route path='reports/*' element={<ReportsRouter />} />
                <Route path='my-plans/*' element={<PlansRouter />} />
                <Route path='endpoints/*' element={<EndPointsRouter />} />
                <Route
                  path=''
                  element={<PrivateRoute component={DashboardHome} />}
                ></Route>
                {/* <Route path="alerts/*" element={<AlertRouter />} /> */}
                {/* <Route path='feedbacks/*' element={<FeedbackRouter />} /> */}
                {/* <Route path='lists/*' element={<ListRouter />} /> */}
                {/* <Route path='users/*' element={<UserRouter />} /> */}
                {/* <Route path='*' element={<DashboardRouter />}></Route> */}
              </Route>
            </Route>
          </Routes>
        </Router>
        </ThemeProvider>
    </div>
  )
}
// import {useState }from 'react'
// import axios from 'axios';
// import { prettyPrintJson } from 'pretty-print-json';

// const roles = ["Free","Standard","Premium"]
// const userRole = roles[(Math.random() * roles.length) | 0]

// function App() {
//   const [country,setCountry] = useState('')
//   const [showDetail,setShowDetail] = useState(false)
//   const [countryData,setCountryData] = useState({})
//   const [loading,setLoading] = useState(false)
//   const [limit, setLimit] = useState(null)


//   const countrySearch = async ()  => {
//     setLoading(true)
//     const countryAlp = country.charAt(0).toUpperCase() + country.slice(1).toLowerCase()
//     const elem = document.getElementById('country');
//     try{
//       // const {data} = await axios.get(`http://localhost:3000/api/countries/detail/633527b216531be396a7680c/public?countryName=${countryAlp}`)
//       const response = await axios.get(`/api/country/one?role=${userRole}`)
//       console.log(response)
//       setLimit(response.headers["x-ratelimit-remaining"])
//       // console.log(response.data)
//       elem.innerHTML = prettyPrintJson.toHtml(response.data);      
//     }catch(err) {
//       console.log(err)
      
//       elem.innerHTML = prettyPrintJson.toHtml({"error":err.response.data});
//     }
//     setShowDetail(true)
//       setLoading(false)
//   }
//   return (
//     <div className="App">
//       <header className="App-header">
//         {/* <img src={logo} className="App-logo" alt="logo" /> */}
//         <h4>
//           CAAPI
//         </h4>
//         <p>Country & Alerts API</p>
//         <div>
//         <input type="text" placeholder="Enter Country Name" value={country} 
//           onChange={(e)=>setCountry(e.target.value)}
//           className="countryField"
//         />
//         <button type="submit" onClick={countrySearch} className="submitBtn">Search</button>
//         <br />
//         <p>My Role: {userRole}</p>
//         {limit && <p>API Limit Remaining : {limit}</p>}
//         {loading && (<div className='loader'></div>)}
//         </div>
//         <div className='jsonOutput'>
//         <output className='json-container' id="country">
//         </output>
//         </div>
//       </header>

//     </div>
//   );
// }

export default App;
