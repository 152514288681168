import React from "react";

import { Route, Routes } from "react-router-dom";

import  EndpointScreen from "../../screens/Endpoints/EndpointScreen";
import { PrivateRoute } from "../middlewares/PrivateRoute";

export const EndPointsRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<PrivateRoute component={EndpointScreen} />}
      />
    </Routes>
  );
};
