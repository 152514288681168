import React from 'react'
// import { Box, Button, ButtonGroup, IconButton } from "@mui/material";

import { useCreateNewApiKeyMutation, useListApiKeysQuery, useDeleteApiKeyMutation} from '../../state/apiKeySlice'

const APIKeyScreen = () => {

  const {data,isLoading} = useListApiKeysQuery()
  const [addToken, { isLoading: addingToken }] = useCreateNewApiKeyMutation()
  const [deleteToken,{isLoading:deletingToken}] = useDeleteApiKeyMutation()

  const deleteTokenBtn = (tokenKey)=>{
    alert('Token key deleted')
    deleteToken(tokenKey)
  }
  const onTokenAdd = () =>{
    alert('Token added')
    addToken()
  }
  return (
    <>
    <h3>Your API Key</h3>
    <hr/>
    {data && data.map((dt,index)=> (
      <li key={index}>
        {dt.tokenKey}
        {/* <span onClick={() =>deleteTokenBtn(dt.tokenKey)} style={{color:'red'}}>&nbsp;Delete</span> */}
      </li>
    ))}
    </>
  )
}

export default APIKeyScreen