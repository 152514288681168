import React from "react";
import { Box, Typography } from "@mui/material";

import AppScreenshot from "../../assets/images/login.png";

export const GetStartedOpposite = ({ isLoginMode = true }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        textAlign: "center",
        // marginBlock: "1rem",
        backgroundColor: "#fafafa",
        height: "100%",
        width: "100%",
        paddingTop: "4rem",
        paddingBottom: "3rem",
        justifyContent:"center" 
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: "8px"}}>
        {!isLoginMode ? "Login to your account" : "Register for an account"}
      </Typography>

      <Typography variant="subtitle1" className="text-secondary">
        {!isLoginMode
          ? "Enter your credentials to login to your account"
          : "Signup and start integrating to your system"}
      </Typography>
    </Box>
  );
};
