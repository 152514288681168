import { apiSlice } from "./apiSlice";

export const plansSlice = apiSlice.injectEndpoints({
    tagTypes:["Plans"],
    endpoints: (build)=> ({
        listPlans: build.query({
            query: ()=>{
                return {
                    url:`/plans`,
                }
            },
            providesTags: (result) =>
            !result ? []
            : result
        }),
    }),
    overrideExisting: false,
})

export const {
    useListPlansQuery
} = plansSlice