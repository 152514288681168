import React from "react";

import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

export const LineChart = ({ data = [], chartOptions, height = 300 }) => {
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "API Usage",
      },
    },
    ...chartOptions,
  };
  return (
    <div style={{ height }}>
      <Line options={lineChartOptions} data={data} />
    </div>
  );
};
