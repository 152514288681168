import React from "react";

import { Route, Routes } from "react-router-dom";

import  PlansScreen from "../../screens/Plans/PlansScreen";
import ShopScreen from "../../screens/Plans/ShopScreen";
import { PrivateRoute } from "../middlewares/PrivateRoute";

export const PlansRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<PrivateRoute component={PlansScreen} />}
      />
      <Route
        path="/upgrade"
        element={<PrivateRoute component={ShopScreen} />}
      />
    </Routes>
  );
};
