import React from 'react'
import MaterialTable from "material-table";
import { useListUsageLogsQuery } from '../../state/usageSlice'

const ReportScreen = () => {

  const {
    data=[],
    isLoading,
    isFetching
   } = useListUsageLogsQuery()

const usageLogs = data.map((m)=>({
  apiKey: m?.apiKey,
  endpoint:m?.endpoint,
  statusCode:m?.statusCode,
  execTime:new Date(m?.execTime).toLocaleString(),
}))
  // const getData = [...usageLogs]
  const columns = [
    {title: 'API Key',field:'apiKey'},
    {title: 'Endpoint',field:'endpoint'},
    {title: 'Status Code',field:'statusCode'},
    {title: 'Execution Time',field:'execTime'}

  ]
  return (
    <>
  {isLoading ? (<p>Loading...</p>) : (
    <MaterialTable
    columns={columns}
    data={usageLogs}
    title="API Logs"
    options={{
      pageSize:20,
      pageSizeOptions: [20, 50, 100]
    }}
  />
  )}
    
    </>
  )
}

export default ReportScreen