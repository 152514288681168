import React from "react";

import { Route, Routes } from "react-router-dom";

import  ReportScreen from "../../screens/Reports/ReportScreen";
import { PrivateRoute } from "../middlewares/PrivateRoute";

export const ReportsRouter = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<PrivateRoute component={ReportScreen} />}
      />
    </Routes>
  );
};
