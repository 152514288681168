import { apiSlice } from "./apiSlice";

export const apiKeySlice = apiSlice.injectEndpoints({
    tagTypes:["APIKeys"],
    endpoints: (build)=> ({
        listApiKeys: build.query({
            query: ()=>{
                return {
                    url:`/apiKey`,
                }
            },
            providesTags: (result) =>
            !result ? []
            : result
        }),
        createNewApiKey : build.mutation({
            query:()=>({
                url:'/apikey/create',
                method:'POST',
            }),
            invalidatesTags: [{ type: "API Keys", id: "LIST" }],
        }),
        deleteApiKey : build.mutation({
            query:(token)=>({
                url:`/apikey/${token}`,
                method:'DELETE',
            }),
            invalidatesTags: [{ type: "API Keys", id: "LIST" }],
        })
    }),
    overrideExisting: false,
})

export const {
    useListApiKeysQuery,
    useCreateNewApiKeyMutation,
    useDeleteApiKeyMutation
} = apiKeySlice