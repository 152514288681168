import { apiSlice } from "./apiSlice";

export const usageSlice = apiSlice.injectEndpoints({
    tagTypes:["Usages"],
    endpoints: (build)=> ({
        listUsages: build.query({
            query: ()=>{
                return {
                    url:`/apilogs`,
                }
            },
            providesTags: (result) =>
            !result ? []
            : result
        }),
        listUsageLogs: build.query({
            query: ()=>{
                return {
                    url:`/apilogs/list`,
                }
            },
            providesTags: (result) =>
            !result ? []
            : result
        }),
    }),
    overrideExisting: false,
})

export const {
    useListUsagesQuery,
    useLazyListUsagesQuery,
    useListUsageLogsQuery,
    useLazyListUsageLogsQuery,
} = usageSlice