// import { IoMdRemoveCircleOutline as DiscardIcon } from "react-icons/io";
// import RedundantIcon from "@mui/icons-material/Replay";
// import ActiveIcon from "@mui/icons-material/PlaylistAddCheckCircle";

// export const DEMOTE_OPTIONS = {
//   0: {
//     id: 0,
//     name: "Discard",
//     icon: <DiscardIcon fontSize="small" />,
//     value: "Discarded",
//   },
//   1: {
//     id: 1,
//     name: "Discard as redundant",
//     icon: <RedundantIcon fontSize="small" />,
//     value: "Redundant",
//   },
//   2: {
//     id: 2,
//     name: "Discard as inactive",
//     icon: <ActiveIcon fontSize="small" />,
//     value: "Inactive",
//   },
// };

export const DATE_RANGE_FILTER = ["Today", "Last 7 Days", "Last 30 Days"];
