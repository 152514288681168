import React from "react";
import clsx from "clsx";

import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  // useTheme
} from "@material-ui/core/styles";

import { Container } from "@mui/system";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import {
  Avatar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import { useAuth } from "../../hooks/useAuth";
import { removeCredentials } from "../../state/authSlice";
import { apiSlice } from "../../state/apiSlice";

import './darkmode.css'

const _drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: 10,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderBottom: "1px solid lightgray",
  },
  appBarShift: {
    width: `calc(100% - ${_drawerWidth}px)`,
    marginLeft: _drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: _drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: _drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -_drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Logo = () => {
  return (
    <Box sx={{ paddingInline: "10px" }}>
      <Link to="/dashboard" underline="none">
        <Typography variant="h6" color="primary">
        AssistX-API
        </Typography>
      </Link>
    </Box>
  );
};

const GreetUser = () => {
  const {
    user: { name },
  } = useAuth();

  const date = new Date();
  const time = date.getHours();

  let greeting = "";

  if (time < 12) greeting = "Good Morning";
  if (time >= 12) greeting = "Good Afternoon";
  if (time >= 18) greeting = "Good Evening";

  return (
    <div>
      <span style={{ color: "gray" }}>{`${greeting},`}</span> {name}
    </div>
  );
};

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const opened = Boolean(anchorEl);

  const { user } = useAuth();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // methods
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(removeCredentials());
    dispatch(apiSlice.util.resetApiState());
    navigate("/login");
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <GreetUser />

      <>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={opened ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={opened ? "true" : undefined}
          >
            <Avatar
              alt="User Avatar"
              src={`https://avatars.dicebear.com/api/adventurer/${user?._id}.svg`}
              sx={{
                width: 36,
                height: 36,
                cursor: "pointer",
                border: "1px solid lightgray",
                padding: "2px",
              }}
            />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={opened}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem component={Link} to="/dashboard/profile">
            <ListItemIcon>
              <AccountCircleOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>

          <MenuItem component={Link} to="/dashboard/settings">
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>


          <Divider />
          <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </>
    </Box>
  );
};

export default function SidebarLayout({ sidebar: SidebarMenus }) {
  const classes = useStyles();
  // const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  // methods
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Container maxWidth="xl">
          <Toolbar style={{ margin: "0 -20px" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <Box
              display="flex"
              flexGrow={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <div className={clsx(open && classes.hide)}>
                  <Logo />
                </div>
              </div>
              <AccountMenu />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Logo />

          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
        </div>

        <Divider />

        <SidebarMenus />
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{ position: "relative" }}
      >
        <div className={classes.drawerHeader} />

        <Container maxWidth="xl" style={{ padding: 0, position: "relative" }}>
          <Outlet />
        </Container>
      </main>
    </div>
  );
}
