import React from 'react'
import { Grid, Card, CardContent,Typography } from '@mui/material'

const EndpointScreen = () => {

    const output =`Status: 200
    [
        {
            "name": "Afghanistan",
            "cca2": "AF"
        },
        {
            "name": "Albania",
            "cca2": "AL"
        },
        {
            "name": "Algeria",
            "cca2": "DZ"
        },
        {
            "name": "American Samoa",
            "cca2": "AS"
        }
        ...etc
    ]`

    const errorOutput = `
    Status 401: Unauthorized
    {
        "message": "No such apikey found"
    }
    Status 400: Bad Request
    {
        "message": "No api key found in request"
    }
    Status 429: Too many request
    `
    const countryOutput = `
    {
        "_id": "6289fae01797586bbe47e4cf",
        "name": "Nepal",
        "officialName": "Federal Democratic Republic of Nepal",
        "cca2": "NP",
        "cca3": "NPL",
        "ccn3": "524",
        "cioc": "NEP",
        "fifa": "NEP",
        "region": "Asia",
        "tld": ".np",
        "idd": "977",
        "emergency": {
            "police": "100",
            "fire": "101",
            "ambulance": "102"
        },
        "electricity": {
            "voltage": "230",
            "frequency": "50",
            "socketTypes": [
                "C",
                "D",
                "M"
            ]
        },
        "capital": "Kathmandu",
        "area": "147181",
        "population": "29136808",
        "startOfWeek": "Sunday",
        "drivingSide": "Left",
        "currency": {
            "code": "NPR",
            "name": "Nepalese Rupee",
            "symbol": "&#8360;"
        },
        ....
    }
    `
    const alertOutput = `
    [
        {
            "title": "Government Body Warns of Increased Health Threats From Climate ... - U.S. News & World Report",
            "location": [
                {
                    "type": "Point",
                    "coordinates": [
                        13.406324205429891,
                        52.520604704040345
                    ],
                }
            ],
            "radius": 10,
            "description": "<a href="https://news.google.com/rss/articles/CBMijAFodHRwczovL3d3dy51c25ld3MuY29tL25ld3MvaGVhbHRoLW5ld3MvYXJ0aWNsZXMvMjAyMy0wNi0wMS9nb3Zlcm5tZW50LWJvZHktd2FybnMtb2YtaW5jcmVhc2VkLWhlYWx0aC10aHJlYXRzLWZyb20tY2xpbWF0ZS1jaGFuZ2UtaW4tZ2VybWFuedIBAA?oc=5" target="_blank">Government Body Warns of Increased Health Threats From Climate ...</a>&nbsp;&nbsp;<font color="#6f6f6f">U.S. News & World Report</font>",
            "advice": "<p style="text-align: justify;">Travelers should be aware of the potential health risks to take necessary precautions. This may include using insect repellents, wearing protective clothing, and staying informed about any disease outbreaks or health advisories in the areas they plan to visit. It is advisable to consult with healthcare professionals or refer to official health guidelines for specific recommendations based on the destination and time of travel.</p>",
            "event": "<p style="text-align: justify;">Germany's Robert Koch Institute, the disease control agency, has issued a warning stating that rising temperatures caused by global warming will increase the risks of heat stroke, vector-borne diseases, and other health hazards in the country. The institute's reports highlight potential problems such as lung diseases from forest fires and agricultural dust, skin cancer due to increased ultraviolet radiation during prolonged periods of cloud-free weather, and the emergence of new disease threats like Hyalomma ticks and Asian tiger mosquitoes in previously colder regions. Another concern is the proliferation of Vibrio bacteria in warmer brackish water, which can cause severe illness in individuals with weakened immune systems. Heat waves pose the greatest threat, with scientists predicting more frequent and extreme occurrences. In Germany, approximately 4,500 deaths were attributed to heat waves last year.</p>",
            "content": "<a href="https://news.google.com/rss/articles/CBMijAFodHRwczovL3d3dy51c25ld3MuY29tL25ld3MvaGVhbHRoLW5ld3MvYXJ0aWNsZXMvMjAyMy0wNi0wMS9nb3Zlcm5tZW50LWJvZHktd2FybnMtb2YtaW5jcmVhc2VkLWhlYWx0aC10aHJlYXRzLWZyb20tY2xpbWF0ZS1jaGFuZ2UtaW4tZ2VybWFuedIBAA?oc=5" target="_blank">Government Body Warns of Increased Health Threats From Climate ...</a>&nbsp;&nbsp;<font color="#6f6f6f">U.S. News & World Report</font>",
            "summary": "<a href="https://news.google.com/rss/articles/CBMijAFodHRwczovL3d3dy51c25ld3MuY29tL25ld3MvaGVhbHRoLW5ld3MvYXJ0aWNsZXMvMjAyMy0wNi0wMS9nb3Zlcm5tZW50LWJvZHktd2FybnMtb2YtaW5jcmVhc2VkLWhlYWx0aC10aHJlYXRzLWZyb20tY2xpbWF0ZS1jaGFuZ2UtaW4tZ2VybWFuedIBAA?oc=5" target="_blank">Government Body Warns of Increased Health Threats From Climate ...</a>&nbsp;&nbsp;<font color="#6f6f6f">U.S. News & World Report</font>",
            "articleLink": "https://news.google.com/rss/articles/CBMijAFodHRwczovL3d3dy51c25ld3MuY29tL25ld3MvaGVhbHRoLW5ld3MvYXJ0aWNsZXMvMjAyMy0wNi0wMS9nb3Zlcm5tZW50LWJvZHktd2FybnMtb2YtaW5jcmVhc2VkLWhlYWx0aC10aHJlYXRzLWZyb20tY2xpbWF0ZS1jaGFuZ2UtaW4tZ2VybWFuedIBAA?oc=5",
            "riskLevel": 2,
            "scrapeMethod": "Automatic",
            "publishedDate": "2023-06-01T22:00:34.718Z",
            "reviewDate": "2023-06-01T22:00:34.718Z",
            "rating": 8,
            "stage": "Published",
            "expiresIn": "2023-06-15T00:00:00.000Z",
            "uuid": "O73ARUXG6D8Z"
        },
        ...
    ]
    `
  return (
    <>
    <h3>Available Endpoints</h3>
    <Grid container spacing={1}>
    <Card sx={{width:'1000px'}}>
        <CardContent>
            <Typography>
                <h3>Countries List</h3>
            </Typography>
            <Typography>
                <code className='codeBar'>
                    GET https://api.assistxgateway.com/v1/country/?apiKey=<em>YOUR-API-KEY</em>
                </code>
                <p>This will return list of the countries with their cca2 name</p>
                <code className='codeBar'>
                <pre>{output}</pre>
                </code>
            </Typography>
        </CardContent>
    </Card>

    <Card sx={{width:'1000px',marginTop:'20px'}}>
        <CardContent>
            <Typography>
                <h3>Country Detail</h3>
            </Typography>
            <Typography>
                <code className='codeBar'>
                    GET https://api.assistxgateway.com/v1/country/<em>np</em>/detail?apiKey=<em>YOUR-API-KEY</em>
                </code>
                <p>This will return detail of the country from their official cca2 name</p>
                <code className='codeBar'>
                <pre>{countryOutput}</pre>
                </code>
            </Typography>
        </CardContent>
    </Card>

    <Card sx={{width:'1000px',marginTop:'20px'}}>
        <CardContent>
            <Typography>
                <h3>Alert Lists</h3>
            </Typography>
            <Typography>
                <code className='codeBar'>
                    GET https://api.assistxgateway.com/v1/country/alerts/?apiKey=<em>YOUR-API-KEY</em>
                </code>
                <p>This will return last 10 alerts list.</p>
                <code className='codeBar'>
                <pre>{alertOutput}</pre>
                </code>
            </Typography>
        </CardContent>
    </Card>
    
    <Card  sx={{width:'1000px',marginTop:'20px'}}>
        <CardContent>
        <Typography>
                <h4>Error Response</h4>
            <code className='codeBar'>
            <pre>{errorOutput}</pre>
            </code>
            </Typography>
        </CardContent>
    </Card>
    </Grid>
    </>
  )
}

export default EndpointScreen