import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {   useStripe, useElements, Elements, CardElement } from "@stripe/react-stripe-js";
import './ShopStyle.css'
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
const stripe = loadStripe(
  "pk_test_b9mRb14xm3btzxvYgk534HGl00QZpi72w9"
);

const ShopScreen = () => {
  const location = useLocation()
  const amountPerMonth = location.state.amount || 0
  return (
    <Elements stripe={stripe}>
      <CheckoutForm amount={amountPerMonth}/>
    </Elements>
  );
};

const CheckoutForm = ({amount}) => {
    const { user } = useAuth();
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const payMoney = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
        return;
        }
        setPaymentLoading(true);
        // const getSecretKey = await axios.post('/api/plans/payment-intent',{'amount':(amount*100).toFixed(0)})
        const subsOutput = await axios.post('/api/plans/payment-intent',{'customer':user.stripeId})
        // const secretKey = getSecretKey.data.clientSecret
        // // const clientSecret = paymentIntent.client_secret;
        // const paymentResult = await stripe.confirmCardPayment(secretKey, {
        // payment_method: {
        //     card: elements.getElement(CardElement),
        //     billing_details: {
        //     name: user.name,
        //     email:user.email
        //     },
        // },
        // });
        
        setPaymentLoading(false);
        // console.log(paymentResult)
        if (subsOutput.error) {
        alert(subsOutput.error.message);
        } else {
        if (subsOutput.paymentIntent.status === "succeeded") {
            alert("Success!");
        }
        }
    };
  return (
    <div
      style={{
        padding: "3rem",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>${amount} per Month</p>
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white"
                  } 
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
              onClick={payMoney}
            >
              {isPaymentLoading ? "Loading..." : "Pay"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ShopScreen