import React from "react";

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { selectLoggedInUser } from "../../state/authSlice";

export const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const loggedInUser = useSelector(selectLoggedInUser);

  if (!loggedInUser) return <Navigate to="/signin" />;

//   if (loggedInUser)
//     return <Navigate to="/dashboard/" />;

  // if (loggedInUser && !loggedInUser.selectedEntity.isActive)
  //   return <Navigate to="/dashboard/entities/select" />;

  return <Component {...otherProps} />;
};
